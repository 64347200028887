<template>
    <div>
        <el-dialog :title="titleName" :visible.sync="show" width="30%">
            <el-input :placeholder="placeholderText" v-model.trim="input" class="input-with-select">
                <el-button slot="append" icon="el-icon-plus" @click="onInput"></el-button>
            </el-input>
            <div class="box-main">
                <el-tag closable @close="handleClose(index)" v-for="(item, index) in nameList" :key="index">{{
                    item
                }}</el-tag>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button :loading="loading" type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    inject: ["getlist"],
    props: {
        type: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            show: false,
            input: "",
            nameList: [],
            id: "",
            loading: false,
        };
    },
    methods: {
        handleClose(index) {
            this.nameList.splice(index, 1);
        },
        onInput() {
            if (!this.input) return this.$message.error("不能为空值");
            this.nameList.push(this.input);
            this.input = "";
        },
        init(val) {
            this.nameList = this.type == "developer" ? val.developer.filter(Boolean) : val.nameList.filter(Boolean);
            this.id = val.id;
            this.show = true;
            this.input = "";
        },
        async submit() {
            let req = {
                enterpriseId: this.id,
            };
            if (this.type == "developer") {
                req.developer = this.nameList.join("|");
            } else {
                req.name = this.nameList.join("|");
            }
            this.loading = true;
            let { data: res } = await this.$http.post(this.reqUrl, req);
            this.loading = false;
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                this.getlist();
                this.show = false;
            }
        },
    },
    computed: {
        titleName() {
            return this.type == "developer" ? "开发者" : "简称库";
        },
        placeholderText() {
            return this.type == "developer" ? "请输入开发者名称" : "请输入简称库名称";
        },
        reqUrl() {
            return this.type == "developer" ? "/admin/Enterprise/addDeveloperList" : "/admin/Enterprise/addNameList";
        },
    },
};
</script>

<style lang="less" scoped>
/deep/.el-tag {
    margin-right: 10px;
    margin-bottom: 10px;
}
.box-main {
    margin-top: 20px;
}
</style>
