<template>
  <div>
    <el-card>
      <nav class="out">
                <nav class="input">
                    <el-input v-model="form_2.name" size="medium" placeholder="公司名称" />
                </nav>
              <nav class="input" style="width: auto">
                <!-- {{findCity(provinceId).toString()}} -->
                <el-select
                    v-model="provinceId"
                    placeholder="省份"
                    size="medium"
                    style="width:150px;margin-right: 5px;"
                >
                  <el-option label="全部" :value="-1" />
                  <el-option
                      v-for="(item, index) in province && province"
                      :key="index"
                      :label="item.name"
                      :value="item.code"
                  />
                </el-select>
                <el-select v-model="cityId" placeholder="城市" size="medium" style="width:150px">
                  <el-option label="全部" :value="-1" />
                  <el-option
                      v-for="(item, index) in cityList"
                      :key="index"
                      :label="item.name"
                      :value="item.code"
                  />
                </el-select>
              </nav>
                <nav class="input" style="width: 150px">
                    <el-select v-model="form_2.isHide" placeholder="状态" size="medium">
                        <el-option label="全部" :value="-1" />
                        <el-option label="上架" :value="0" />
                        <el-option label="下架" :value="1" />
                    </el-select>
                </nav>
                <nav class="input" style="width: 150px">
                    <el-select v-model="form_2.type" placeholder="类型" size="medium">
                        <el-option label="全部" :value="-1" />
                        <el-option
                            v-for="(item, index) in typeList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        />
                    </el-select>
                </nav>
                <nav class="input" style="width: 150px">
                    <el-select v-model="form_2.business" placeholder="行业" size="medium">
                        <el-option label="全部" :value="-1" />
                        <el-option
                            v-for="(item, index) in businessList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        />
                    </el-select>
                </nav>
                <nav class="input" style="width: 150px">
                    <el-select v-model="form_2.subBusiness" placeholder="二级行业" size="medium">
                        <el-option label="全部" :value="-1" />
                        <el-option
                            v-for="(item, index) in subBusinessList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        />
                    </el-select>
                </nav>
                <!-- <nav class="input" style="width: 150px">
          <el-select
            v-model="form_2.serverArea"
            placeholder="业务区域"
            size="medium"
          >
            <el-option label="全部" :value="-1" />

            <el-option
              v-for="(item, index) in this.$store.state.enterprise_config &&
                this.$store.state.enterprise_config.enterpriseServerArea"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            />
          </el-select>
        </nav> -->
                <nav class="input" style="width: 150px">
                    <el-select v-model="form_2.top" placeholder="推荐" size="medium">
                        <el-option label="全部" :value="-1" />
                        <el-option label="不推荐" :value="0" />
                        <el-option label="推荐" :value="1" />
                    </el-select>
                </nav>
                <nav class="input" style="width: 150px">
                    <el-select v-model="form_2.people" placeholder="公司规模" size="medium">
                        <el-option label="全部" :value="-1" />
                        <el-option
                            v-for="(item, index) in this.$store.state.enterprise_config &&
                                this.$store.state.enterprise_config.enterprisePeople"
                            :key="item.key"
                            :label="item.value"
                            :value="item.key"
                        />
                    </el-select>
                </nav>
                <nav class="input" style="width: 150px">
                    <el-select v-model="form_2.financing" placeholder="融资情况" size="medium">
                        <el-option label="全部" :value="-1" />
                        <el-option
                            v-for="(item, index) in this.$store.state.enterprise_config &&
                                this.$store.state.enterprise_config.enterpriseFinancing"
                            :key="item.key"
                            :label="item.value"
                            :value="item.key"
                        />
                    </el-select>
                </nav>
                <nav class="input" style="width:150px ;">
                    <el-select v-model="form_2.egId" placeholder="企业等级">
                        <el-option
                            :label="item.name"
                            :value="item.id"
                            v-for="(item, index) in $store.state.config && $store.state.config.enterpriseGrade"
                            :key="index"
                        >
                        </el-option>
                    </el-select>
                </nav>
                <nav class="input" style="width: 333px;">
                    <label
                        for=""
                        style="
                        font-size: 14px;
                        float: left;
                        transform: translateY(9px);
                    "
                        >认证时间：</label
                    >
                    <div style="display:inline-block;width: 250px;">
                        <el-date-picker
                            v-model="form_2.certCreateDate"
                            @change="form_2.createDate = []"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            style="width: 100%"
                            size="medium"
                        />
                    </div>
                </nav>
                <nav class="input" style="width: 333px;">
                    <label
                        for=""
                        style="
                        font-size: 14px;
                        float: left;
                        transform: translateY(9px);
                    "
                        >创建时间：</label
                    >
                    <div style="display:inline-block;width: 250px;">
                        <el-date-picker
                            v-model="form_2.createDate"
                            type="daterange"
                            @change="form_2.certCreateDate = []"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            style="width: 100%"
                            size="medium"
                        />
                    </div>
                </nav>
                <nav class="input" style="width: auto">
                    <fuzzySelection
                        size="medium"
                        ref="fuzzySelection"
                        type="4"
                        :value.sync="form_2.activityId"
                    ></fuzzySelection>
                </nav>
                <nav class="input" style="width: 150px">
                    <el-button size="small" @click="reset">重置</el-button>
                    <el-button type="primary" size="small" @click="subimt">{{ "搜索" }}</el-button>
                </nav>
            </nav>
        </el-card>
        <el-card style="margin-top: 10px">
            <el-table
                :data="list"
                style="width: 100%"
                border
                stripe
                :header-cell-style="rowClass"
                @sort-change="sortChange"
            >
                <el-table-column prop="id" label="ID" />

                <el-table-column prop="id" label="Logo">
                    <template slot-scope="{ row }">
                        <img v-if="row.logo" :src="baseurl + yu + row.logo" alt="" style="height: 35px;" />
                        <img
                            v-else
                            src="https://static-cdn.yfchuhai.com/www.yfchuhai.com/static/wechat/img/enterprise/portrait.png"
                            alt=""
                        />
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="企业简称">
                    <template slot-scope="{ row }">
                        <!-- <a
              :href="`https:/${yu}/enterprise/${row.id}.html`"
              target="_blank"
              style="text-decoration: underline;"
              >{{ row.name }}</a
            > -->
                        <span>{{ row.name }}</span>

                        <div style="margin-top: 10px;" @click="$refs.abbreviated.init(row)">
                            <el-button type="primary" size="mini">简称库</el-button>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="企业全称">
                    <template slot-scope="{ row }">
                        <span>
                            <el-tag v-if="row.regStatus" size="small" :type="row.regStatus == '注销' ? 'info' : ''">
                                {{ row.regStatus }}
                            </el-tag>
                            {{ row.fullName }}
                        </span>
                        <p>
                            <a
                                v-if="row.website"
                                :href="row.website.includes('http') ? row.website : 'http://' + row.website"
                                target="_blank"
                                >（查看官网）</a
                            >
                        </p>
                    </template>
                </el-table-column>
                <el-table-column prop="gradeName" label="企业等级">
                    <template slot-scope="{ row }">
                        <p>{{ row.gradeName }}</p>
                        <p v-if="row.egId > 1">VIP到期时间：{{ row.vipEndTime }}</p>
                    </template>
                </el-table-column>
                <el-table-column prop="desc" label="管理员">
                    <template slot-scope="{ row }">
                        <div v-if="row.user">
                            <img :src="baseurl + yu + row.user.avatar" alt="" style="height: 35px;" />
                            <p>
                                {{ row.user.realName }}<template v-if="row.user.realName && row.user.job"> · </template
                                >{{ row.user.job }}
                            </p>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="desc" label="活动展商" width="240">
                    <template #header>
                        <span>活动参展</span>
                    </template>
                    <template slot-scope="{ row }">
                        <div v-if="row.activityId">
                            <div>
                                <!-- <span style="flex-shrink: 0;"> 活动：</span> -->
                                <div style="margin-bottom: 5px;" v-for="(item, index) in row.activityName" :key="index">
                                    <el-tag size="small">{{ item }}</el-tag>
                                </div>
                            </div>
                            <p>展位号：{{ row.exhibitorCode }}</p>
                            <p>
                                排序值：{{ row.exhibitorOrderNum }}
                                <el-tooltip effect="dark" placement="top">
                                    <div slot="content">数值越大，显示越靠前</div>
                                    <i class="el-icon-warning-outline" style="margin-left: 5px;" />
                                </el-tooltip>
                            </p>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="city" label="地址">
                    <template slot-scope="{ row }">
                        {{ row.provinceName + "-" + row.cityName + "-" + row.address }}
                    </template>
                </el-table-column>
                <el-table-column prop="type" label="类型行业">
                    <template slot-scope="{ row }">
                        {{ row.typeName + "-" + row.businessName.toString() }}
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="areaName" label="业务区域" /> -->
                <el-table-column prop="peopleName" label="公司规模" />

                <el-table-column prop="financingName" label="融资情况" />
                <el-table-column prop="isHide" label="状态">
                    <template slot-scope="{ row }">
                        <el-tag type="warning" size="small" class="box">{{ row.isHide ? "下架" : "上架" }}</el-tag>
                        <el-tag type="warning" size="small" class="box">{{ row.top ? "推荐" : "不推荐" }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="标签">
                    <template #default="{row}">
                        <!-- <el-tag>{{row.label}}</el-tag> -->
                        <Remark :row="row" rowKey="label" url="/admin/Enterprise/editLabel" @getlist="getlist"></Remark>
                    </template>
                </el-table-column>
              <el-table-column width="100px" label="联系方式（数据来自企查查）" >
                <template #default="{row}">
                  <div>手机号码：{{row.phone?row.phone:'暂无'}}</div>
                  <div>邮箱：{{row.email?row.email:'暂无'}}</div>
                </template>
              </el-table-column>
                <el-table-column prop="vipCreateTime" label="审核时间" />
                <el-table-column prop="remark" label="排序">
                    <template #header>
                        <span>排序</span>
                        <br /><span style="font-size:12px">（当前最大值：{{ orderMax }}）</span>
                        <el-tooltip effect="dark" placement="top">
                            <div slot="content">数值越大，显示越靠前</div>
                            <i class="el-icon-warning-outline" style="margin-left: 5px;" />
                        </el-tooltip>
                    </template>
                    <template slot-scope="{ row }">
                        <RemarkOrder
                            :row="row"
                            :val="{
                                url: '/admin/Enterprise/editOrderNum',
                                pw: 'enterprise_edit_order',
                            }"
                            @getlist="getlist"
                        />
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="150">
                    <template slot-scope="ew">
                        <el-button
                            v-show="$store.state.btn_if.indexOf('user_enterprise_edit') != -1"
                            type="text"
                            icon="el-icon-edit"
                            size="mini"
                            class="skyblue"
                            @click="edit(ew.row.id)"
                            >编辑</el-button
                        >
                        <el-button
                            v-show="$store.state.btn_if.indexOf('user_enterprise_delete') != -1"
                            type="text"
                            icon="el-icon-delete"
                            size="mini"
                            class="red"
                            @click="open(ew.row.id)"
                            >删除</el-button
                        >
                        <el-button
                            v-show="$store.state.btn_if.indexOf('user_enterprise_edit_grade') != -1"
                            type="text"
                            icon="el-icon-camera"
                            size="mini"
                            class="green"
                            @click="show_qy(ew.row)"
                            >修改等级</el-button
                        >
                        <el-button
                            v-show="$store.state.btn_if.indexOf('enterprise_edit_activity') != -1"
                            type="text"
                            icon="el-icon-s-ticket"
                            size="mini"
                            style="color:coral"
                            @click="editorialActivityExhibitor(ew.row)"
                            >活动合作&展商
                        </el-button>
                        <el-button
                            v-show="$store.state.btn_if.indexOf('user_enterprise_hide') != -1"
                            type="text"
                            icon="el-icon-s-promotion"
                            size="mini"
                            class="yellow"
                            @click="show(ew.row.id, ew.row.isHide == 0 ? 1 : 0)"
                        >
                            {{ ew.row.isHide == 0 ? "下架" : "上架" }}
                        </el-button>
                        <el-button
                            v-show="$store.state.btn_if.indexOf('user_enterprise_top') != -1"
                            type="text"
                            icon="el-icon-share"
                            size="mini"
                            style="color: tan;"
                            @click="setTop(ew.row, ew.row.top == 0 ? 1 : 0)"
                            >{{ ew.row.top == 0 ? "推荐" : "不推荐" }}</el-button
                        >
                        <el-button
                            v-show="$store.state.btn_if.indexOf('enterprise_list_change_manager') != -1"
                            type="text"
                            icon="el-icon-s-custom"
                            size="mini"
                            class="pink"
                            @click="edit_man(ew.row)"
                            >更改管理员</el-button
                        >
                        <el-button
                            v-show="$store.state.btn_if.indexOf('enterprise_developer') != -1"
                            type="text"
                            icon="el-icon-s-finance"
                            size="mini"
                            class="pink"
                            @click="$refs.developer.init(ew.row)"
                            >新增开发者</el-button
                        >
                        <el-button
                            v-show="$store.state.btn_if.indexOf('user_enterprise_business_info') != -1"
                            type="text"
                            icon="el-icon-s-custom"
                            size="mini"
                            style="color:orange"
                            @click="accessToBusinessInformation(ew.row)"
                            >获取工商信息</el-button
                        >
                        <el-button
                            v-show="$store.state.btn_if.indexOf('user_enterprise_product') != -1"
                            type="text"
                            icon="el-icon-takeaway-box"
                            size="mini"
                            style="color:tan"
                            @click="enterpriseProducts(ew.row)"
                            >企业产品</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :current-page="currentPage"
                background
                :page-sizes="[3, 5, 10, 15]"
                :page-size="pagesize"
                layout="total, prev, pager, next,jumper"
                :total="total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            />
        </el-card>
        <el-dialog title="信息" :visible.sync="showinfo" width="40%" @close="">
            <el-form ref="form" :model="form_show" label-width="80px" :inline="false" size="normal">
                <el-form-item label="手机号">
                    <el-input v-model="form_show.phone" />
                </el-form-item>
                <el-form-item label="邮箱">
                    <el-input v-model="form_show.email" />
                </el-form-item>
            </el-form>
        </el-dialog>
        <chagne ref="chageref" @getlist="getlist" />
        <Drawers ref="drawer" :title="'采集工商信息'"></Drawers>
        <abbreviated-library ref="abbreviated"></abbreviated-library>
        <abbreviated-library ref="developer" type="developer"></abbreviated-library>
        <pass ref="pass" :dialog-visible.sync="p_a" :row="p_a_f" @getlist="getlist" />
        <exhibitor ref="exhibitor" @getlist="getlist"></exhibitor>
    </div>
</template>

<script>
let that;
import $ from "jquery";
import show from "../../components/countDowns/show.vue";
// import mixins from '../../mixin/getdiction'
import pass from "./enterprise_cert_list/pass.vue";
import remark from "../../components/remark/orderNum.vue";
import abbreviatedLibrary from "./enterprise_list/abbreviatedLibrary.vue";
import exhibitor from "./exhibitor.vue";
import fuzzySelection from "/src/components/fuzzySelection";
export default {
    provide() {
        return {
            getlist: this.getlist,

        };
    },
    components: {
        Show: show,
        RemarkOrder: remark,
        chagne: () => import("./enterprise_change.vue"),
        pass,
        abbreviatedLibrary,
        exhibitor,
        fuzzySelection,
    },
    data() {
        return {
          cityList: [],
          provinceId: "",
          cityId: "",
          city: "",
          province: "",
            dioA: false,
            showinfo: false,
            date: [],
            title: "",
            p_a: false,
            p_a_f: {},
            year: "",
            publishStatus: "",
            reportArea: "",
            reportBusiness: "",
            order: "",
            confing: "",
            btn: true,
            action: {
                action: "enterprise",
            },
            dioaddkuai: false,
            currentPage: 1,
            pagesize: 10,
            list: [],
            pai: [],
            paiform: {},
            value: "",
            dialogVisible: false,
            dialogVisiblexui: false,
            baseurl: this.$store.state.env.cos,
            yu: "/" + this.$store.state.env.domain,
            total: 0,
            proof: false,
            form: {
                title: "",
                source: "",
                picture: "",
                content: "",
                desc: "",
                areaCode: "",
                banner: "",
                viewType: "",
                businessId: "",
            },
            form_1: {
                id: "",
                title: "",
                source: "",
                picture: "",
                content: "",
                desc: "",
                areaCode: "",
                banner: "",
                viewType: "",
                businessId: "",
            },
            form_2: {
                page: 1,
                pageSize: 10,
                name: "",
                isHide: "",
                type: "",
                business: "",
                subBusiness: "",
                serverArea: "",
                createDate: [],
                people: "",
                financing: "",
                top: "",
                egId: "",
                certCreateDate: [],
                activityId: "",
                // order: ''
            },
            form_3: {
                phone: this.$cookies.get("phone"),
            },
            s_form: {
                page: 1,
                pageSize: 10,
                name: "",
                isHide: "",
                type: "",
                business: "",
                subBusiness: "",
                serverArea: "",
                createDate: [],
                people: "",
                financing: "",
                top: "",
                egId: "",
                certCreateDate: [],
                activityId: "",
            },
            imageUrl: "",
            imageUrl_1: "",
            header: {
                Token: sessionStorage.getItem("token"),
            },
            form_show: {},
            area: [],
            orderMax: 0,
            typeList: [],
            businessList: [],
            subBusinessList: [],
        };
    },
    watch: {
      provinceId(val) {
        this.findCity(val);
      },
        "form_2.type"(newValue, oldValue) {
            this.form_2.business = "";
            this.form_2.subBusiness = "";
            this.getType("1");
        },
        "form_2.business"(newValue, oldValue) {
            this.form_2.subBusiness = "";
            this.getType("2");
        },
    },
    // mixins: [mixins],
    beforeCreate() {
        that = this;
    },
    updated() {
        //   console.log(this.form);
    },

    created() {
        // this.getconfig()
        // this.getconfig('user')
      this.getear();
        this.getlist();
        sessionStorage.setItem("action", "enterprise");
    },
    mounted() {
        // console.log(rules.req('撒打算'))
        this.getType();
        // this.$refs.chageRef.dialogVisible=true
        // console.log(this.confing_d)
    },
    methods: {
      op() {
        this.getc();
        this.editform.city = "";
      },
      // 省市
      async findCity(val) {
        if (!val) {
          return;
        }
        let { data: res } = await this.$http.get("/admin/Area/getList?code=" + val);
        console.log(res.data);
        this.cityList = res.data;
        this.cityId = "";
        // console.log(this.cityList)
      },
      getear() {
        this.$http.get("/admin/Area/getList").then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.province = res.data;
            // console.log(this.province,'//////');
            // console.log(this.editform.province)
          }
        });
      },
      getc() {
        this.$http.get("/admin/Area/getList?code=" + this.editform.province).then(({ data: res }) => {
          if (res.errorCode == 200) {
            console.log(this.editform.province);
            console.log(res);
            this.city = res.data;
          }
        });
      },
        edit_man(val) {
            this.$refs.chageref.dialogVisible = true;
            console.log(val.id);
            this.$refs.chageref.form.id = val.id;
        },
        accessToBusinessInformation(row) {
            this.$router.push({
                path: "/industrialIn_iformation_edit",
                query: {
                    id: row.id,
                },
            });
        },
        reset() {
            Object.assign(this.form_2, this.$options.data.call(this).form_2);
            this.s_form = { ...this.form_2 };
            this.provinceId='';
            this.cityId='';
            this.getlist();
        },
        subimt() {
            this.currentPage = 1;
            this.form_2.page = 1;
            this.s_form = { ...this.form_2 };

            this.getlist();
        },
        check() {
            console.log(1);
        },
        information(row) {
            this.showinfo = true;
            // this.form_show = row
            this.$http.get("/admin/UserSpeaker/getById", { params: { id: row.id } }).then(({ data: res }) => {
                if (res.errorCode == 200) {
                    this.form_show = res.data;
                }
            });
        },
        editorialActivityExhibitor(row) {
            this.$refs.exhibitor.init(row);
        },
        datum(row) {
            location.href = this.baseurl + this.yu + row.document;
        },
        sortChange(c) {
            console.log(c);
            if (c.order) {
                var s = "activity_" + c.prop;
                if (c.prop == "orderNum") {
                    s = "report_order_num";
                } else if (c.prop == "views") {
                    s = "report_views";
                } else {
                    s = "report_" + c.prop;
                }
                var p = c.order.slice(0, c.order.length - 6);
                this.order = s + " " + p;
            } else {
                this.order = "";
            }

            this.getlist();
        },
        showinput(row) {
            row.pai = true;
            this.list = [...this.list];
            setTimeout(() => {
                $(".id input").focus();
            }, 20);
        },
        addkuai() {
            this.addkuai = false;
        },
        getea() {
            this.$http.get("/admin/ReportCategory/getList").then(({ data: res }) => {
                if (res.errorCode == 200) {
                    this.area = res.data;
                }
            });
        },
        rowClass() {
            return "background:#F5F7FA;";
        },
        handleSizeChange(e) {
            this.pagesize = e;
            console.log(e);
        },
        handleCurrentChange(e) {
            // this.getlist()
            this.s_form.page = e;
            this.form_2.page = e;

            this.getlist(this.s_form);
        },
        getlist(val) {
            var form = val || this.form_2;
            this.$http
                .get("/admin/Enterprise/getList", {
                    params: {
                        ...form,
                      province: this.provinceId,
                      city: this.cityId,
                        createDate: form.createDate.join("~"),
                        certCreateDate: form.certCreateDate.join("~"),
                    },
                })
                .then(({ data: res }) => {
                    if (res.errorCode == 200) {
                        // console.log(res)
                        this.list = res.data.list;
                        // console.log(this.list)
                        this.total = res.data.totalCount;
                        this.orderMax = res.data.orderMax;
                    }
                });
        },
        checkf(val) {
            this.form_3 = val;
            // console.log(this.form_3)
        },
        async beforedown() {
            const phone = this.$cookies.get("phone");
            if (!phone) {
                this.$message.error("没有绑定手机号");
                return;
            }
            if (!this.proof) {
                this.dioA = true;
            } else {
                this.download();
            }
        },
        async download() {
            if (this.btn) {
                this.btn = false;

                if (false) {
                    // this.$message.error(res.message)
                    // return
                } else {
                    const { data: res } = await this.$http.get("/admin/UserSpeaker/exportExcel", {
                        params: {
                            ...this.form_2,
                            ...this.form_3,
                        },
                    });
                    this.proof = true;
                    this.btn = true;
                    if (res.errorCode == 200) {
                        this.$message.success(res.message);
                        location.href = res.data.url;
                        this.dioA = false;
                    }
                }
            }
        },
        add() {},
        handleAvatarSuccess(res, file) {
            if (res.errorCode == 200) {
                this.form.banner = res.data.url;
            }
            //   console.log(file)
            //   console.log(imgurlbase)
        },
        async getType(val) {
            if (val == 1) {
                var { data: res } = await this.$http.get(
                    `/admin/EnterpriseCategory/getList?keyword=&page=1&pageSize=100&order=&typeId=${this.form_2.type}&businessId=`
                );
                this.businessList = res.data.list;
                return;
            }
            if (val == 2) {
                var { data: res } = await this.$http.get(
                    `/admin/EnterpriseCategory/getList?keyword=&page=1&pageSize=100&order=&typeId=${this.form_2.type}&businessId=${this.form_2.business}`
                );
                this.subBusinessList = res.data.list;
                return;
            }
            var { data: res } = await this.$http.get(
                "/admin/EnterpriseCategory/getList?keyword=&page=1&pageSize=100&order=&typeId=0&businessId="
            );
            this.typeList = res.data.list;
        },

        // beforeAvatarUpload(file) {
        //   const isJPG = file.type === 'image/jpeg'
        //   const isLt2M = file.size / 1024 / 1024 < 2

        //   if (!isJPG) {
        //     this.$message.error('上传头像图片只能是 JPG 格式!')
        //   }
        //   if (!isLt2M) {
        //     this.$message.error('上传头像图片大小不能超过 2MB!')
        //   }
        //   return isJPG && isLt2M
        // },
        handleAvatarSuccess_1(res, file) {
            if (res.errorCode == 200) {
                this.form_1.banner = res.data.url;
            }
            console.log(file);
            //   console.log(imgurlbase)
        },
        beforeAvatarUpload_1(file) {
            const isJPG = file.type === "image/jpeg";
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error("上传头像图片只能是 JPG 格式!");
            }
            if (!isLt2M) {
                this.$message.error("上传头像图片大小不能超过 2MB!");
            }
            return isJPG && isLt2M;
        },

        addedit() {
            if (this.btn) {
                // this.form.picture = this.form.picture.slice(this.baseurl.length)

                this.$http.post("/admin/News/edit", this.form_1).then(({ data: res }) => {
                    if (res.errorCode == 200) {
                        this.dialogVisiblexui = false;
                        this.$message.success(res.message);
                        this.getlist();
                    }
                    this.btn = true;
                });
            }
            this.btn = false;
        },
        enterpriseProducts(row) {
            this.$router.push({
                path: "/enterprise_products",
                query: {
                    id: row.id,
                },
            });
        },
        edit(id) {
            // sessionStorage.setItem('flag', false)
            this.$router.push({
                name: "enterprise_add",
                query: {
                    id: id,
                },
            });
        },
        open(id) {
            this.$confirm("此操作将永久删除该, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.$http.delete("/admin/Enterprise/delete", { params: { id: id } }).then((res) => {
                        //   console.log(res)
                        if (res.data.errorCode == 200) {
                            this.$message({
                                type: "success",
                                message: "删除成功!",
                            });
                            if (this.total % 10 == 1) {
                                this.currentPage--;
                            }
                            this.getlist();
                        }
                    });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                    });
                });
        },

        setTop(row, val) {
            // console.log(1)
            // console.log(row, id)
            this.$http
                .post("/admin/Enterprise/top", {
                    id: row.id,
                    top: val,
                })
                .then(({ data: res }) => {
                    if (res.errorCode == 200) {
                        this.$message.success(res.message);
                        this.getlist();
                    } else {
                        this.$message({
                            message: res.message,
                            type: "error",
                            customClass: "zZindex",
                        });
                    }
                    // this.btn1 = true
                });
        },
        show_qy(row) {
            this.p_a = true;
            this.p_a_f = row;
        },
        show(id, el) {
            this.$http.post("/admin/Enterprise/hide", { id: id, isHide: el }).then(({ data: res }) => {
                if (res.errorCode == 200) {
                    this.$message.success(res.message);
                    this.getlist();
                }
            });
        },
        chagne(row) {
            this.$http
                .post("/admin/UserSpeaker/editService", {
                    id: row.id,
                    waId: row.waId.join(","),
                })
                .then(({ data: res }) => {
                    if (res.errorCode == 200) {
                        this.getlist();
                    } else {
                        this.getlist();
                    }
                });
        },
    },
};
</script>

<style lang="less" scoped>
.out {
    // width: 500px;

    margin-bottom: 20px;
    .input {
        width: 37%;
        display: inline-block;
        margin-right: 30px;
    }
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}
.avatar {
    width: 178px;
    height: 178px;
    display: block;
}
.skyblue {
    color: skyblue;
}
.green {
    color: green;
}
.yellow {
    color: pink;
}
.red {
    color: red;
}
.out .input {
    width: 18%;
}
.el-card {
    position: relative;
}
.out .right {
    float: right;
}
.box {
    display: inline-block;
    margin: 5px;
}
</style>
